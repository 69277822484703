module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MXJ9JFZJ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Отримуй подарунки 🎁 за покупки🛍 🍕👗🧸👠!","short_name":"Отримуй подарунки 🎁 за покупки🛍 🍕👗🧸👠!","start_url":"/","background_color":"#31479a","theme_color":"#31479a","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"38529f34da9cf0add66b4adea167fa00"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
